<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <!-- Receive list -->
        <material-card
          :title="$t('report.detailQuantityVoucherSoldOfStaffInAgent.list')"
          color="green"
          flat
          full-width
        >
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="voucherReportHeaders"
            :items="voucherReportList"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }">
              <td
                v-for="(header, index) in voucherReportHeaders"
                :key="header.value + index"
              >
                <div
                  v-if="header.value == 'voucherSerials'"
                  style="max-height: 150px; overflow: auto; white-space: nowrap;">
                  <p
                    v-for="(serial, index) in item[header.value]"
                    :key="serial + index">
                    {{ (index + 1) + '. ' + serial }}
                  </p>
                </div>
                <span v-else>{{ item[header.value] }}</span>
              </td>
            </template>
          </v-data-table>
        </material-card>
        <pagination
          :total="paginate.totalPage"
          :current-page="paginate.currentPage"
          :row-per-page="paginate.rowPerPage"
          @onPageChange="onPageChange"
        />
      </v-flex>
    </v-layout>
    <loading-bar :is-loading="isLoading" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dateUtils from 'utils/dateUtils'
import functionUtils from 'utils/functionUtils'
export default {
  data: () => ({
    voucherReportHeaders: [
      { text: 'common.nonumber', value: 'stt' },
      { text: 'voucherHistory.dateAction', value: 'dateAction' },
      { text: 'report.quantityVoucherSupplierToAgent.workpieceCode', value: 'workpieceCode' },
      { text: 'evoucher.issuance.workpiece', value: 'issuanceCode' },
      { text: 'report.releaseAgent', value: 'fromEntity' },
      { text: 'report.staffName', value: 'staffName' },
      { text: 'report.staffEmail', value: 'staffEmail' },
      { text: 'evoucher.voucherSerial', value: 'voucherSerials' },
      { text: 'customer.emailCustomer', value: 'customerEmail' }
    ],
    voucherReportList: [],
    paginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    isLoading: false
  }),
  computed: {
    ...mapGetters([
      'QUANTITY_VOUCHER_SOLD_OF_STAFF_IN_AGENT_DATA'
    ])
  },
  watch: {
    QUANTITY_VOUCHER_SOLD_OF_STAFF_IN_AGENT_DATA () {
      let res = this.QUANTITY_VOUCHER_SOLD_OF_STAFF_IN_AGENT_DATA
      // Handle paginate
      this.paginate.currentPage = res.paginate.currentPage
      this.paginate.totalPage = res.paginate.totalPage
      this.paginate.rowPerPage = res.paginate.perPage / 2
      // Handle response data
      let data = res.results
      this.voucherReportList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let obj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          dateAction: this.formatDate(data[i].date_created),
          workpieceCode: data[i].workpiece ? data[i].workpiece.code : '',
          issuanceCode: functionUtils.concatSuffixPrefix(data[i].issuance),
          staffName: functionUtils.concatFullname(data[i].from_user.last_name, data[i].from_user.first_name),
          staffEmail: data[i].from_user.email,
          fromEntity: data[i].from_entity.name,
          voucherSerials: data[i].voucher_serials,
          customerEmail: data[i].to_user.email
        }
        this.voucherReportList.push(obj)
      }
    }
  },
  created () {
    this.getQuantityVoucherSoldInAgent()
  },
  methods: {
    /**
     * Format date
     */
    formatDate: function (date) {
      return dateUtils.formatBeautyDate(date)
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.paginate.currentPage = page
      this.getQuantityVoucherSoldInAgent()
    },
    /**
     * Get report statistical quantity voucher supplier to agent
     */
    getQuantityVoucherSoldInAgent: function () {
      let filter = {
        params: {
          page: this.paginate.currentPage,
          isGetDetail: true
        }
      }
      this.isLoading = true
      this.GET_QUANTITY_VOUCHER_SOLD_OF_STAFF_IN_AGENT(filter).then(
        function () {
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    ...mapActions([
      'GET_QUANTITY_VOUCHER_SOLD_OF_STAFF_IN_AGENT'
    ])
  }
}
</script>

<style>

</style>
